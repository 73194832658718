










































































































































































































































































































































































































































































































































































































































import type { Venda } from '@/models' 
import { DetalhesDoPagamento, InformacoesDeTransporte, ItemDaVendaComReferencias, MotivoCancelamento, UsuarioBase, VendaComReferencias } from '@/models'
import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator'
import { Inject } from 'inversify-props'
import { formatarCnpjOuCpf, formatarMoeda } from '@/shareds/formatadores'
import { dateTimeToPtBrFormat, dateToPtBrFormat } from '@/shareds/date/date-utils'
import { displayVariantes } from '@/shareds/produto-shareds'
import {
	obterTotalDoItem,
	obterSubTotalDoItem,
	obterCnpjOuCpfDaVenda,
	baixarXmlDaNotaFiscal,
	imprimirValeTroca,
	setarVendaComoPresente,
	obterTotalDaVenda,
	imprimirCupomFuncionario,
	imprimirEtiquetaDeEntrega,
	baixarXmlCompletoDaNotaFiscal,
	obterTotalLiquidoDoItem,
} from '@/shareds/venda-shareds'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import copy from 'copy-to-clipboard'
import DialogoDeCpfDoCliente from './DialogoDeCpfDoCliente.vue'
import Confirmacao from '@/components/ui/Confirmacao.vue'
import { AnexoUseCase, CancelarVendaUseCase, EditarUmaVendaUseCase, FindLojaUseCase, FindUsuarioUseCase, FindVendaUseCase, VendedorEdit } from '@/usecases'
import type { PagamentoServiceAdapter } from '@/usecases'
import ImprimirNotaFiscal from '@/usecases/venda/ImprimirNotaFiscal'
import DialogoDeEdicaoDeTransporteDaVenda from '@/components/venda/DialogoDeEdicaoDeTransporteDaVenda.vue'
import DialogoDeEdicaoDeClienteDaVenda from '@/components/venda/DialogoDeEdicaoDeClienteDaVenda.vue'
import DialogoDeEdicaoDoPrazoDeDevolucao from '@/components/venda/DialogoDeEdicaoDoPrazoDeDevolucao.vue'
import DialogoDeEdicaoDeVolumesDaVenda from '@/components/venda/DialogoDeEdicaoDeVolumesDaVenda.vue'
import FaturamentoPedidoDaVenda from '@/usecases/venda/FaturamentoPedidoDaVenda'
import { PedidoDeVenda, SituacaoDoPedidoDeVenda } from '@/models/venda/PedidoDeVenda'
import { imprimirCupomSemValorFiscal } from '@/shareds/pedidos/impressao-cupom-sem-valor-fiscal'
import { gerarComprovanteDeDemonstracao80mm, imprimirRomaneio } from '@/shareds/pedidos/impressao-de-romaneio'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import DialogoDeInformarVendedorNaVenda from '@/views/application/venda/DialogoDeInformarVendedorNaVenda.vue'
import DialogoDeNumeroDeImpressoes from './DialogoDeNumeroDeImpressoes.vue'
import { FormValeTroca, ValeTroca } from '@/models/venda/ValeTroca'
import DialogoDeInformarRepresentanteComercial from '@/views/application/venda/DialogoDeInformarRepresentanteComercial.vue'
import mapErrosSefaz from '@/shareds/fiscal/tabelas/mapErrosSefaz'
import ConfirmacaoComMotivo from '../ui/ConfirmacaoComMotivo.vue'
import DialogoDeEdicaoDeVendaOrigem from './DialogoDeEdicaoDeVendaOrigem.vue'
import DialogoDeSelecaoDeModalidadeDeVenda from './DialogoDeSelecaoDeModalidadeDeVenda.vue'
import DialogoDeAnexoDeArquivos from '@/views/application/anexo/DialogoDeAnexoDeArquivos.vue'
import BotaoDeDownloadDeAnexo from '../anexo/BotaoDeDownloadDeAnexo.vue'
import { AnexoDosProcessos, TipoDeProcesso } from '@/models/anexo/AnexoDosProcessos'
import { Operador } from '@/models/venda/Operador'
import ListagemDeCartasDeCorrecao from '@/views/application/venda/ListagemDeCartasDeCorrecao.vue'
import DialogoDeEdicaoDoTicket from './DialogoDeEdicaoDoTicket.vue'

@Component({
	components: {
		DialogoDeCpfDoCliente,
		Confirmacao,
		DialogoDeEdicaoDeTransporteDaVenda,
		DialogoDeEdicaoDeClienteDaVenda,
		DialogoDeEdicaoDoPrazoDeDevolucao,
		DialogoDeEdicaoDeVolumesDaVenda,
		DialogoDeInformarVendedorNaVenda,
		DialogoDeNumeroDeImpressoes,
		DialogoDeInformarRepresentanteComercial,
		ConfirmacaoComMotivo,
		DialogoDeEdicaoDeVendaOrigem,
		DialogoDeSelecaoDeModalidadeDeVenda,
		DialogoDeAnexoDeArquivos,
		BotaoDeDownloadDeAnexo,
		ListagemDeCartasDeCorrecao,
		DialogoDeEdicaoDoTicket,
	},
})
export default class DialogoDeDetalhesDaVenda extends Vue {
	@Prop() venda!: Venda
	@Prop({ type: Boolean, default: true }) exibirDetalheCompleto!: boolean
	@Prop({ type: String , default: "" }) identificadorExterno2!: string
	@Prop({ type: Boolean, default: false }) esconderBotaoImprimirDanfe!: boolean
	@Ref() dialogoDeCpfDoCliente!: DialogoDeCpfDoCliente
	@Ref() confirmacaoDeCpfNaNota!: Confirmacao
	@Ref() confirmacaoDeRemocaoDeVendaOrigem!: Confirmacao
	@Ref() abrirDialogoDePrazoDevolucao!: DialogoDeEdicaoDoPrazoDeDevolucao
	@Ref() abrirDialogoDeEdicaoDeVolumesDaVenda!: DialogoDeEdicaoDeVolumesDaVenda
	@Ref() dialogoDeInformarVendedorNaVenda!: DialogoDeInformarVendedorNaVenda
	@Ref() dialogoDeNumeroDeImpressoes!: DialogoDeNumeroDeImpressoes
	@Ref() dialogoDeInformarRepresentanteComercial!: DialogoDeInformarRepresentanteComercial
	@Ref() dialogoDeConfirmacaoComMotivo!: ConfirmacaoComMotivo
	@Ref() dialogoDeConfirmacaoComMotivoParaCancelarAVenda!: ConfirmacaoComMotivo
	@Ref() dialogoDeSelecaoDeModalidadeDeVenda!: DialogoDeSelecaoDeModalidadeDeVenda
	@Ref() dialogoDeAnexoDeArquivos!: DialogoDeAnexoDeArquivos
	@Ref() listagemDeCartasDeCorrecao!: ListagemDeCartasDeCorrecao
	@Ref() dialogoDeEdicaoDoTicket!: DialogoDeEdicaoDoTicket

	@Inject('PagamentoServiceAdapter')
	private pagamentoService!: PagamentoServiceAdapter

	mostra: boolean | null = null

	findVendaUseCase = new FindVendaUseCase()
	findLojaUseCase = new FindLojaUseCase()
	findUsuarioUseCase = new FindUsuarioUseCase()
	vendas: Venda[] | null = null
	vendaComReferencias: VendaComReferencias | null = null
	numeroPgn: number | null = null
	formValeTroca: FormValeTroca[] = []
	cancelarVendaUseCase = new CancelarVendaUseCase()
	editarUmaVendaUseCase = new EditarUmaVendaUseCase()
	anexoUseCase = new AnexoUseCase()

	mapErrosSefaz = mapErrosSefaz
	dateTimeToPtBrFormat = dateTimeToPtBrFormat
	dateToPtBrFormat = dateToPtBrFormat
	formatarMoeda = formatarMoeda
	obterTotalDoItem = obterTotalDoItem
	obterSubTotalDoItem = obterSubTotalDoItem
	obterCnpjOuCpfDaVenda = obterCnpjOuCpfDaVenda
	formatarCnpjOuCpf = formatarCnpjOuCpf
	displayVariantes = displayVariantes
	obterTotalLiquidoDoItem = obterTotalLiquidoDoItem

	esconderBotoes: boolean | false = false
	esconderDiv = true
	processandoImpressao = false
	baixandoXml = false
	baixandoXmlCompleto = false
	faturando = false
	carregando = false
	descontoTotalDaVenda = 0
	time = null
	menu2 = false
	modal2 = false
	itensDaVenda: ItemDaVendaComReferencias[] = []
	anexo: AnexoDosProcessos | null = null
	salvando = false
	numeroTicket: string | null = null

	mostrarDialogoDeUploadDeArquivos() {
		this.dialogoDeAnexoDeArquivos.mostrar(this.venda.id, this.$route.fullPath.replace("/", "") as TipoDeProcesso)
	}

	retornarVenda() {
		this.mostra = false
		this.$emit('devolverVenda', this.venda)
	}

	abrirDialogo() {
		this.abrirDialogoDePrazoDevolucao.mostrar()
	}

	abrirDialogoDeRemocaoDeVendaOrigem() {
		this.confirmacaoDeRemocaoDeVendaOrigem.mostrar()
	}

	abrirDialogoDeEdicaoDeVolumes() {
		this.abrirDialogoDeEdicaoDeVolumesDaVenda.mostrar()
	}

	abrirDialogoDeEdicaoDoTicket() {		
		this.dialogoDeEdicaoDoTicket.mostrar()
	}

	abrirDialogoDeCancelamento(item: ItemDaVendaComReferencias) {
		if (this.itensAhCancelar.length == 1) {
			if (!this.podeCancelarPedido) {
				AlertModule.setError(
					'Você não tem a autorização para cancelar o último item desta venda.' +
					' Essa ação resultaria no cancelamento da venda e seu acesso não está liberado para realizá-la.',
				)
				return
			}
			this.dialogoDeConfirmacaoComMotivoParaCancelarAVenda.mostrar(item)
		} else {
			this.dialogoDeConfirmacaoComMotivo.mostrar(item)
		}
	}

	atualizarPrazo(prazo: string) {
		if (!this.vendaComReferencias) return
		this.vendaComReferencias.prazoDevolucao = prazo
	}

	atualizarVolumes(volumesAtualizados: string[]) {
		if (!this.vendaComReferencias) return
		this.vendaComReferencias.volumes = volumesAtualizados
	}

	atualizarTicket(ticketAtualizado: string) {
		if (!this.vendaComReferencias) return
		this.vendaComReferencias.numeroTicket = ticketAtualizado
		this.$emit('atualizarTicket', true)
	}	

	confirmarImpressoes() {
		this.dialogoDeNumeroDeImpressoes.mostrando()
	}

	abrirDialogoDaListagemDasCartasDeCorrecao() {
		this.listagemDeCartasDeCorrecao.mostrar(this.venda)
	}

	mensagemDeAlerta() {
		if(this.esconderBotoes === true) {
			AlertModule.setWarning('Para dar sequencia as demais impressões, é necessario verificar os produtos!')
		}else{
			return
		}
		
	}

	get itensAhCancelar() {
		if (!this.vendaComReferencias) return []
		return this.vendaComReferencias.itens.filter(item => !item.motivoCancelamento)
	}

	get prazoDevolucao() {
		return this.vendaComReferencias?.prazoDevolucao
	}

	get motivoCancelamento() {
		return this.vendaComReferencias?.motivoCancelamento?.motivo;
	}

	get volumes() {
		return this.vendaComReferencias?.volumes
	}

	get volumesFormatado() {
		return this.vendaComReferencias?.volumes ? this.vendaComReferencias?.volumes.join(', ') : 'Sem volumes'
	}

	get podeFaturarPedido() {
		return UserLoginStore.permiteRegraDeNegocio('pode-faturar-pedidos')
	}

	get podeCancelarPedido() {
		return UserLoginStore.permiteRegraDeNegocio('pode-cancelar-pedidos')
	}

	get temRecurso() {
		return UserLoginStore.temAutorizacaoPorOperacaoERecurso
	}

	get liberarImpressaoRomaneio() {
		return this.vendaComReferencias?.produtosVerificados
	}

	get informacoesTransporte(): InformacoesDeTransporte | null {
		if (this.vendaComReferencias) {
			this.vendaComReferencias.informacoesDeTransporte = this.venda.informacoesDeTransporte
		}

		return this.vendaComReferencias?.informacoesDeTransporte || null
	}

	get podeAlterarPrazoDevolucao() {
		return UserLoginStore.permiteRegraDeNegocio('pode-alterar-prazo-de-devolucao')
	}

	get podeImprimirContingencia() {
		if (!this.vendaComReferencias) return false

		return this.vendaComReferencias.notas.filter(nota => nota.cstat === '108' || nota.cstat === '109').length > 0
	}

	get podeCriarCartaDeCorrecao() {
		if (!this.vendaComReferencias) return false

		return this.vendaComReferencias.notas.filter(nota => nota.cstat === '100' && nota.modelo === 'NF-e').length > 0
	}

	get tipoDePagamento(){
		return [...new Set(
			this.vendaComReferencias?.pagamentos
				.map(pagamento => {
					if (pagamento.qtdeDeParcelas > 1)
						return `${pagamento.tipoDePagamento.nome} (${pagamento.qtdeDeParcelas}x)`
					return pagamento.tipoDePagamento.nome}),
		)].join(", ")
	}

	get nomeDosVendedores() {
		if (!this.vendaComReferencias) return 'Sem Vendedores'
		if (this.vendaComReferencias.itens.filter(item => !!item.vendedor).length === 0) return 'Sem vendedores'

		return [...new Set(
			this.vendaComReferencias.itens
				.filter(item => !!item.vendedor)
				.map(item => item.vendedor?.nome),
		)].join(",")
	}

	get capturaTelaAcessada(){
		const rota = window.location.pathname

		if(rota.includes('/pedidos')){
			return false
		} else{
			return true
		}
	}

	get ticket() {
		return this.vendaComReferencias?.numeroTicket ? this.vendaComReferencias?.numeroTicket : 'Não há ticket'
	}
	
	get isPedido() {
		return this.vendaComReferencias?.pedido
	}

	get temPontoDeVenda() {
		return this.vendaComReferencias?.pontoDeVenda
	}

	get possuiAcessoIrrestrito() {
		return UserLoginStore.permiteRegraDeNegocio('acesso-irrestrito')
	}
	
	async removerVendaOrigem() {
		if (!this.venda) return
		
		try {
			this.carregando = true
			await this.editarUmaVendaUseCase.removerVendaOrigem(
				this.venda.id,
			)
			this.buscaVenda()
			AlertModule.setSuccess('Venda salva com sucesso')
			
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.carregando = false
		}
	}
	
	async necessitaVerificacao() {
		if (!this.venda.pontoDeVenda) return
		if (!this.venda.pedido) return
		try {

			this.faturando = true
			this.processandoImpressao = true

			if (this.venda.pedido.situacao) {
				const lojaDosPedidos = await this.findLojaUseCase.findLojaById(this.venda.pontoDeVenda.loja.id)
				this.vendaComReferencias = await this.findVendaUseCase.getVendaComReferencias(this.venda.id)

				if (lojaDosPedidos.configuracaoDaLoja.exigirVerificacaoDeProdutos === true && this.venda.tipoDeTransacao !== 'Devolução') {
					this.esconderBotoes = this.vendaComReferencias.produtosVerificados ? false : true;
					this.esconderDiv = false
				}
			}
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.faturando = false
			this.processandoImpressao = false
		}
	}

	async buscaVenda() {
		if (!this.venda) return
		try {
			this.carregando = true
			this.vendaComReferencias = await this.findVendaUseCase
				.getVendaComReferencias(this.venda.id)
				
			this.venda.vendedor = this.vendaComReferencias.vendedor ? this.vendaComReferencias.vendedor as UsuarioBase[] : null
			this.venda.representanteComercial = this.vendaComReferencias.representanteComercial
				? this.vendaComReferencias.representanteComercial as UsuarioBase
				: null
			this.venda.liberarCrediario = this.vendaComReferencias.liberarCrediario
			this.anexo = await this.anexoUseCase.findAnexoByIdentificador(this.venda.id)
		} catch (e) {
			AlertModule.setError(e)
		}
	}

	get podeImprimirValeTroca(): boolean {
		if (this.venda.tipoDeTransacao !== 'Venda' || this.vendaComReferencias && (this.vendaComReferencias.isConsignado || this.venda.isDemonstracao || this.venda.existeValePresente)) {
			return false;
		}
		return this.venda.pontoDeVenda?.loja.configuracaoDaLoja?.impressaoDeValeTroca || false
	}

	get podeImprimirEtiquetaDeEntrega(): boolean {
		const notasEmitidas = this.vendaComReferencias?.notas.filter(nota => nota.cstat === '100' && nota.codigoDePostagem !== null)

		if (notasEmitidas && notasEmitidas?.length > 0) return true

		return false
	}

	get podeEditar() {
		return this.temRecurso('alterar', 'vendas')
	}

	get podeImprimirDanfe() {
		if (!this.vendaComReferencias) return false

		if (this.vendaComReferencias.situacao === 'Cancelada') return false
		if (this.vendaComReferencias.pedido) {
			if (this.vendaComReferencias.pedido.situacao === 'Pendente') return false

			return this.vendaComReferencias.pedido.situacao !== 'Cancelado' && (this.vendaComReferencias.pedido.situacao !== 'Devolvido' || this.podeImprimirRecibos)
		}
		if (this.vendaComReferencias.tipoDeTransacao === 'Devolução' || this.vendaComReferencias.tipoDeTransacao === 'Transferência' 
			|| this.vendaComReferencias.tipoDeTransacao === 'Conserto' || this.vendaComReferencias.tipoDeTransacao === 'Ativo'
			|| this.vendaComReferencias.tipoDeTransacao === 'Consumo') return true

		return this.vendaComReferencias.cliente !== null
	}

	get podeImprimirReciboParaAssinaturaDoFuncionario() {
		if (!this.vendaComReferencias) return false

		const temPagamentoCrediatio = this.vendaComReferencias.pagamentos.filter(
			pagamento => pagamento.tipoDePagamento.formaDePagamento == "Crediário" && pagamento.tipoDePagamento.crediarioDoFuncionario,
		)

		return temPagamentoCrediatio.length > 0
			? true
			: false
	}

	get podeImprimirRecibos() {
		return this.venda.notas.every(
			({ cstat }) => !['101', '102'].includes(cstat),
		)
	}

	get podeImprimirNfce() {

		if (this.vendaComReferencias) {
			return this.vendaComReferencias.tipoDeTransacao === 'Venda'
				&& !this.valorExcedenteParaEmissaoDeNfce
				&& (!this.vendaComReferencias.cliente
					|| this.vendaComReferencias.cliente.inscricaoEstadual === 'ISENTO')
				&& this.vendaComReferencias.informacoesDeTransporte.modalidadeDeFrete === 'Sem Frete'
				&& !this.vendaComReferencias.isConsignado
				&& !this.vendaComReferencias.isDemonstracao
				&& this.vendaComReferencias.itens.filter(item => item.isBrinde === true).length === 0
		}

		return false
	}

	get podeEditarTransporte(): boolean {
		const vendaComDanfeEmitida = this.vendaComReferencias?.notas.filter(nota => nota.modelo === 'NF-e' && nota.cstat === '100') || []

		return vendaComDanfeEmitida.length == 0
	}

	get valorExcedenteParaEmissaoDeNfce(): boolean {
		if (!this.vendaComReferencias) return false

		return this.vendaComReferencias
			.pagamentos
			.reduce((total, { valor }) => total + valor, 0) > 10000
	}

	validacaoDoTituloDaOrigemVenda() {
		if (this.vendaComReferencias?.vendaOrigem && (
			(
				this.vendaComReferencias.vendaOrigem.isConsignado &&
				!this.vendaComReferencias.isConsignado
			) || (
				this.vendaComReferencias.vendaOrigem.isDemonstracao &&
				!this.vendaComReferencias.isDemonstracao
			) || (
				(this.vendaComReferencias.isDemonstracao || this.vendaComReferencias.isConsignado) && 
				(this.vendaComReferencias.vendaOrigem.isConsignado || this.vendaComReferencias.vendaOrigem.isDemonstracao)
			)
		)) {
			return "Demonstração/Consignação Origem"
		} else if (!this.vendaComReferencias?.vendaOrigem) {
			return "Venda Origem"
		} else if (this.vendaComReferencias?.tipoDeTransacao === 'Venda' && this.vendaComReferencias.vendaOrigem) {
			return "Venda trocada de"
		}
		return "Devolvido de"
	}

	cancelarVenda(motivoDoCancelamento: MotivoCancelamento) {
		const vendaResumida = {
			...this.venda,
		}

		this.$emit('cancelarVenda', vendaResumida, motivoDoCancelamento)
		this.mostra = false
	}

	async cancelarItem(motivoDoCancelamento: MotivoCancelamento, item: ItemDaVendaComReferencias) {
		if (!motivoDoCancelamento) {
			AlertModule.setError('Necessário um motivo para cancelamento')
			return
		}
		if (!item) {
			AlertModule.setError('Item a cancelar não encontrado')
			return
		}
		if (!this.vendaComReferencias) {
			return
		}
		if (!this.venda) {
			return
		}

		const indice = this.vendaComReferencias.itens.findIndex((itemVenda) => itemVenda.id === item.id)
		if (indice == -1) {
			AlertModule.setError('Item a cancelar não encontrado')
			return
		}
		this.vendaComReferencias.itens.splice(indice, 1, {
			...this.vendaComReferencias.itens[indice],
			loadingItem: true,
		})

		try {
			const params = {
				motivo: motivoDoCancelamento,
				itemId: item.id,
			};

			const itemAtualizado = await this.cancelarVendaUseCase.cancelarItem(this.venda.id, params)

			this.vendaComReferencias.itens.splice(indice, 1, {
				...itemAtualizado,
				loadingItem: false,
				cancelando: true,
			})
			AlertModule.setSuccess("Item cancelado com sucesso")

			const vendaAtualizada = await this.findVendaUseCase.getVendaComReferencias(this.vendaComReferencias.id)
			const vendaResumida = {
				...vendaAtualizada,
				total: vendaAtualizada.total,
			}
			await this.buscaVenda()
			this.$emit('atualizarTotalDoPedido', { ...vendaResumida })
		} catch (error: any) {
			AlertModule.setError('Ocorreu um erro ao cancelar o item')
			this.vendaComReferencias.itens[indice].cancelando = false
		}
	}

	async imprimir(imprimirNfe: boolean, isContingencia: boolean) {
		try {
			if(!this.venda.pontoDeVenda) return
			if(!this.vendaComReferencias) return
			

			const temNotaEmitida = this.venda.notas.filter(nota => nota.cstat === '100').length > 0
			const lojaCompleta = await this.findLojaUseCase.findLojaById(this.venda.pontoDeVenda.loja.id)
			const vendaCompleta = await this.findVendaUseCase.find({
				identificador: this.venda.identificador,
			})
			const ufCliente = this.vendaComReferencias.enderecoAdicional ? this.vendaComReferencias.enderecoAdicional.endereco.uf : this.vendaComReferencias.cliente?.enderecosAdicionais?.filter(endereco => endereco.isPadrao)[0].endereco.uf
			const ufLoja = lojaCompleta.endereco.uf;
			const isDemonstracao = this.venda.isDemonstracao || vendaCompleta.content[0].isDemonstracao;

			if(ufLoja !== ufCliente &&
				!lojaCompleta.configuracaoDaLoja.permitirCriarNotaDeDemonstracaoParaForaDoEstado &&
				isDemonstracao &&
				!temNotaEmitida
			) {
				AlertModule.setError("UF da loja e UF do cliente são diferentes. <br>" +
				"Notas de demonstração não podem ser criadas para outros estados.<br> " +
				"Para prosseguir, altere as configurações de loja.");
				return
			}

			if (imprimirNfe && !isContingencia && this.existeNfceAtivaEInvalidaNaVenda) {
				AlertModule.setError('Esta venda possui um cupom fiscal inválido, favor verificar antes de emitir a DANFE')
				return
			}
			this.processandoImpressao = true
			await ImprimirNotaFiscal({
				venda: this.venda,
				imprimirNfe,
				dialogoDeCpfDoCliente: this.dialogoDeCpfDoCliente,
				confirmacaoDeCpfNaNota: this.confirmacaoDeCpfNaNota,
				isContingencia: isContingencia,
			})
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.processandoImpressao = false
		}
		const vendaAtualizada = await this.findVendaUseCase.get(this.venda.id)
		const vendaResumida = {
			...vendaAtualizada,
			total: obterTotalDaVenda(vendaAtualizada),
		}
		await this.buscaVenda()
		this.$emit('input', { ...vendaResumida })
	}

	async imprimirCupomDoPedidoSemValorFiscal() {
		try {
			this.processandoImpressao = true
			const vendaComItens = await this.findVendaUseCase.get(this.venda.id)

			const pagamentos = vendaComItens.pagamentos.filter(pagamento => pagamento.detalhesDoPagamentoId !== null)

			const detalhesDosPagamentos: DetalhesDoPagamento[] = []

			pagamentos.forEach(async (pagamento) => {
				if (pagamento.detalhesDoPagamentoId) {
					const detalhesDoPagamento = await this.pagamentoService.buscarDetalhesDoPagamento(pagamento.detalhesDoPagamentoId)
					detalhesDosPagamentos.push(detalhesDoPagamento)
				}
			})

			await imprimirCupomSemValorFiscal(vendaComItens, detalhesDosPagamentos)
			await this.buscaVenda()
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.processandoImpressao = false
		}
	}

	get existeNfceAtivaEInvalidaNaVenda(): boolean {
		return this.venda.notas.length > 0 &&
			this.venda.notas.some(
				nota => nota.modelo === 'NFC-e' && !['100', '101', '102'].includes(nota.cstat),
			)
	}

	get situacaoDoPedidoDeVenda(): SituacaoDoPedidoDeVenda {
		return this.venda.pedido?.situacao || 'Pendente'
	}

	get podeFaturar(): boolean {
		if (!this.vendaComReferencias) return false;
		return this.vendaComReferencias.itens.some((item) => !item.motivoCancelamento);
	}	

	async faturar() {
		try {
			this.faturando = true
			const pedido: PedidoDeVenda = await FaturamentoPedidoDaVenda({
				venda: this.venda,
			})
			this.venda = {
				...this.venda,
				pedido,
			}

			this.$emit('input', this.venda)
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.faturando = false
		}
	}

	inserirNumeroImpressoes(numeroPgna: number) {
		this.numeroPgn = numeroPgna,
		this.imprimirValeTroca()
	}

	async imprimirValeTroca() {
		if (!this.vendaComReferencias) return
		if (this.venda.pontoDeVenda === null) return
		if (!this.numeroPgn) return
		const lojaCompleta = await this.findLojaUseCase.findLojaById(this.venda.pontoDeVenda.loja.id)
		const valeTrocaForm: FormValeTroca = {
			loja: lojaCompleta,
			venda: this.vendaComReferencias,
			quantidade: this.numeroPgn,
		}
		this.formValeTroca.push(valeTrocaForm)
		try {
			let valeTrocas: ValeTroca[] = []
			const tamanho = 10
			for (let i = 0; i < this.formValeTroca.length; i += tamanho) {
				const algunsFormsDeVale = this.formValeTroca
					.slice(i, i + tamanho)
					.map(valeTroca => ({
						...valeTroca,
					}));
				valeTrocas = [...algunsFormsDeVale]
			}
			await imprimirValeTroca(valeTrocas)
			await setarVendaComoPresente(this.vendaComReferencias.id)
			this.vendaComReferencias.isPresente = true
		} catch (e) {
			AlertModule.setError('Erro ao imprimir o vale troca!')
		} finally {
			this.formValeTroca.length = 0
		}
	}

	ehVendaCanceladaSemNfe() {
		return this.venda.situacao === 'Cancelada' && !this.venda.notas.length
	}

	async baixarXml() {
		if (!this.venda) return
		try {
			this.baixandoXml = true
			await baixarXmlDaNotaFiscal(this.venda)
		} catch (error: any) {
			AlertModule.setError('Nota fiscal com problemas. Não será possível baixar os XMLs.')
		} finally {
			this.baixandoXml = false
		}
	}

	async baixarXmlCompleto() {
		if (!this.venda) return
		try {
			this.baixandoXmlCompleto = true
			await baixarXmlCompletoDaNotaFiscal(this.venda)
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.baixandoXmlCompleto = false
		}
	}

	extractDomain(url) {
		let domain = url.replace(/^https?:\/\//, '')
		domain = domain.split('/')[0]
		domain = domain.split(':')[0]

		return domain;
	}

	async gerarComprovanteDeDemonstracao80mm() { 
		const usuarioId = UserLoginStore.usuario?.id
		const qrData = `${this.extractDomain(window.location.href)}/anexo/${this.$route.fullPath.replace("/", "") as TipoDeProcesso}/${this.venda.id}/${usuarioId}`
		const produtosComReferencia = this.vendaComReferencias?.itens
		try {
			const vendaImpressao = await this.findVendaUseCase.get(this.venda.id)
			await gerarComprovanteDeDemonstracao80mm(qrData, produtosComReferencia, vendaImpressao)
		} catch (error: any) {
			AlertModule.setError(error)
		}
	}

	async imprimirPdfA4Romaneio() { 
		const usuarioId = UserLoginStore.usuario?.id
		const qrData = `${this.extractDomain(window.location.href)}/anexo/${this.$route.fullPath.replace("/", "") as TipoDeProcesso}/${this.venda.id}/${usuarioId}`
		const produtosComReferencia = this.vendaComReferencias?.itens
		try {
			const vendaImpressao = await this.findVendaUseCase.get(this.venda.id)
			await imprimirRomaneio(vendaImpressao, qrData, produtosComReferencia)
		} catch (error: any) {
			AlertModule.setError(error)
		}
	}

	async imprimirCupomFuncionario() {
		this.processandoImpressao = true
		try {
			await imprimirCupomFuncionario(this.venda.id)
		} catch (error: any) {
			AlertModule.setError(error)
			this.processandoImpressao = false
		} finally {
			this.processandoImpressao = false
		}
	}

	async imprimirEtiquetaDeEntrega() {
		this.processandoImpressao = true
		try {
			await imprimirEtiquetaDeEntrega(this.venda.id)
		} catch (error: any) {
			AlertModule.setError(error)
			this.processandoImpressao = false
		} finally {
			this.processandoImpressao = false
		}
	}

	copiarChaveNfe() {
		const nota = this.venda.notas[0]
		if (!nota) return
		copy(nota.chNFe)
	}

	displayTituloDialogoDeImpressao(venda: VendaComReferencias) {
		return venda.cliente && venda.cliente.cnpjOuCpf.length === 11
			? 'CPF na nota?' : 'CNPJ na nota?'
	}

	obterDescontoTotalDaVenda(venda: Venda): number {
		this.findVendaUseCase.get(venda.id)
			.then((vendaComOsItens) => {
				const itensValidosDaVenda = vendaComOsItens.itens.filter((item) => item.motivoCancelamento === null)
				this.descontoTotalDaVenda = itensValidosDaVenda.reduce((total, itemValido) => total + (itemValido.valorDescontoRateadoDoItem || 0), 0)
			})
		return this.descontoTotalDaVenda
	}

	podeEmitirNFCE() {
		if (!this.vendaComReferencias) return false
		const naoTemNFE = this.venda.notas.every(({ modelo }) => modelo !== 'NF-e')
		const valorPermitido = this.vendaComReferencias
			.pagamentos
			.reduce((total, { valor }) => total + valor, 0) <= 10000
		const naoEhDevolucao = this.venda.tipoDeTransacao !== 'Devolução'
		const naoEhVendaCancelada = this.venda.situacao !== 'Cancelada'
		return naoTemNFE
			&& valorPermitido
			&& naoEhDevolucao
			&& naoEhVendaCancelada
	}

	@Watch('mostra')
	onChangeMostra() {
		if (this.mostra) {
			this.buscaVenda()
			this.necessitaVerificacao()
			this.esconderDiv = true
		}
		this.vendaComReferencias = null

	}

	podeBaixarXML() {
		if (!this.vendaComReferencias) return false
		return !!this.vendaComReferencias.notas.length
	}

	podeImprimirRelatorios(): boolean {
		if (!this.vendaComReferencias) return false

		const pagamentosComLinkPendente = this.vendaComReferencias.pagamentos.filter(pagamento => pagamento.link !== null && pagamento.status !== 'Pago')


		if (pagamentosComLinkPendente.length > 0) return false
		if (this.vendaComReferencias.chaveDanfeExterna) return false

		if (this.vendaComReferencias.tipoDeTransacao === 'Venda' || this.vendaComReferencias.tipoDeTransacao === 'Transferência'
			|| this.vendaComReferencias.tipoDeTransacao === 'Outros'
			|| this.vendaComReferencias.tipoDeTransacao === 'Conserto'
			|| this.vendaComReferencias.tipoDeTransacao === 'Ativo'
			|| this.vendaComReferencias.tipoDeTransacao === 'Consumo'
			|| (this.vendaComReferencias.tipoDeTransacao === 'Devolução' && this.vendaComReferencias.tipoNota === 'Saída') 
			|| this.vendaComReferencias.itens.filter(item => !!item.vendaOrigemDoItem).length > 0) {
			const possuiNotasValidas = this.vendaComReferencias.notas.length > 0 &&
				this.vendaComReferencias.notas.every(({ cstat }) => ['100', '103'].includes(cstat))

			return possuiNotasValidas ||
				(this.vendaComReferencias.situacao !== 'Cancelada' &&
					!this.vendaComReferencias.temDevolucao)
		} else {
			const possuiSomenteNotasCanceladas = this.vendaComReferencias.vendaOrigem?.notas.every(({ cstat }) => ['101', '102'].includes(cstat))
			const possuiSomenteNotasValidas = (
				(this.vendaComReferencias.notas?.length || 0) > 0 &&
				this.vendaComReferencias.notas.every(({ cstat }) => ['100', '103'].includes(cstat))
			) || (this.vendaComReferencias.vendaOrigem != undefined && this.vendaComReferencias.vendaOrigem.chaveDanfeExterna != null)

			return (
				this.vendaComReferencias.vendaOrigem?.notas &&
				this.vendaComReferencias.vendaOrigem?.situacao !== 'Cancelada' &&
				!possuiSomenteNotasCanceladas
			) || possuiSomenteNotasValidas
		}
	}

	async abrirDialogoDeVendedor() {
		if (!this.vendaComReferencias) return
		this.dialogoDeInformarVendedorNaVenda.mostrar(this.vendaComReferencias.pontoDeVenda ? this.vendaComReferencias.pontoDeVenda.id : '', this.vendaComReferencias)
	}

	async abrirDialogoDeRepresentante() {
		this.dialogoDeInformarRepresentanteComercial.mostrar()
	}

	atualizarVendedorDaVenda(vendaAtualizada) {
		this.venda.vendedor = vendaAtualizada.vendedor

		if (!this.vendaComReferencias) return
		this.vendaComReferencias.vendedor = vendaAtualizada.vendedor
	}

	atualizarVendedores(edicaoDeVendedor: VendedorEdit) {
		if(!this.vendaComReferencias) return

		this.vendaComReferencias.vendedor = edicaoDeVendedor.vendedores as Operador[]

		this.vendaComReferencias.itens.forEach(item => {
			const indiceDoItem = edicaoDeVendedor.itens.findIndex(itemDaEdicao => itemDaEdicao.id === item.id)
			if (indiceDoItem >= 0) {
				item.vendedor = edicaoDeVendedor.itens[indiceDoItem].vendedor
			}
		})
	}

	atualizarFreteDaVenda(vendaAtualizada) {
		this.venda.informacoesDeTransporte = vendaAtualizada.informacoesDeTransporte
	}

	atualizarRepresentanteComercialDaVenda(vendaAtualizada) {
		this.venda.representanteComercial = vendaAtualizada.representanteComercial

		if (!this.vendaComReferencias) return
		this.vendaComReferencias.representanteComercial = vendaAtualizada.representanteComercial
	}

	abrirDialogoDeModalidadeDeVenda(){
		this.dialogoDeSelecaoDeModalidadeDeVenda.mostrar()
	}

	irPara(item: ItemDaVendaComReferencias) {
		const idProduto = item.produto.tipo === 'Variante'
			? item.produto.idProdutoPai
			: item.produto.id

		if (this.temRecurso('ler', 'produtos')) {
			window.open(`/produtos/${idProduto}`, '_blank')
		}
	}
}
