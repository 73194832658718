import { Venda, ItemDaVendaComReferencias, Endereco } from '@/models'
import pdfMake from 'pdfmake/build/pdfmake'
import type { Content, ContentText, TDocumentDefinitions } from 'pdfmake/interfaces'
import vfs from 'pdfmake/build/vfs_fonts.js'
import print from 'print-js'
import { displayNomeCompletoDoProduto } from '../produto-shareds'
import JsBarcode from 'jsbarcode'
import { dateTimeToPtBrFormat } from '../date/date-utils'
import { obterTotalLiquidoDoItem } from '@/shareds/venda-shareds'
import { formatarMoeda } from '@/shareds/formatadores'
import request from '@/common/request'

function textToBase64Barcode(text: string) {
	const canvas = document.createElement("canvas");
	JsBarcode(canvas, text, {
		height: 60,
		fontSize: 15,
		displayValue: true,
	});
	return canvas.toDataURL("image/png");
}

window.pdfMake.vfs = vfs.pdfMake.vfs;
export async function gerarRomaneio(venda: Venda, qrData: string, produtosComReferencia?: ItemDaVendaComReferencias[]): Promise<string> {
	const loja = venda.pontoDeVenda?.loja || null
	const dataHoraDaNota = venda.notas.filter(({ cstat }) => cstat === '100').flatMap(nota => nota.dhEmi)
	const notaEmitida = venda.notas.filter(nota => nota.cstat === '100' && nota.modelo === 'NF-e')[0]
	const produtos = venda.itens.filter((item) => item.motivoCancelamento === null)
	const produtosFiltrados = produtosComReferencia?.filter((item) => produtos.some(produtoDaVenda => produtoDaVenda.produto.sku === item.produto.sku))
	let endereco = notaEmitida ? {
		id: '',
		rua: notaEmitida.ruaDest,
		numero: notaEmitida.numeroEnderecoDest,
		cep: notaEmitida.cepDest,
		bairro: notaEmitida.barirroDest,
		complemento: notaEmitida.complementoDest,
		cidade: notaEmitida.cidadeDest,
		uf: notaEmitida.ufDest,
		codigoIBGE: '',
		codigoPais: '',
		nomePais: '',
	} as Endereco
		:
		venda.enderecoAdicional ?
			venda.enderecoAdicional.endereco
			:
			venda.cliente?.enderecosAdicionais.filter(enderecoAdicional => enderecoAdicional.isPadrao)[0].endereco

	if (venda.pedido && venda.pedido.enderecoDoPedido) {
		endereco = {
			id: '',
			rua: venda.pedido.enderecoDoPedido.rua,
			numero: venda.pedido.enderecoDoPedido.numero,
			cep: venda.pedido.enderecoDoPedido.cep,
			bairro: venda.pedido.enderecoDoPedido.bairro,
			complemento: venda.pedido.enderecoDoPedido.complemento,
			cidade: venda.pedido.enderecoDoPedido.cidade,
			uf: venda.pedido.enderecoDoPedido.uf,
			codigoIBGE: venda.pedido.enderecoDoPedido.codigoIBGE,
			codigoPais: '',
			nomePais: '',
		} as Endereco
	}
	const docDefinition: TDocumentDefinitions = {
		pageSize: 'A4',
		content: [
			{
				table: {
					widths: ['*'],
					body: [
						[
							{
								columns: [
									{ text: 'ROMANEIO', fontSize: 16, style: 'tableHeader', width: 'auto', bold: true },
									{ text: `${loja?.nomeFantasia || ''}`, fontSize: 11, style: 'tableHeader', width: '*', bold: true, alignment: 'center', margin: [0, 4, 0, 0] },
									{ text: `${venda.identificador}`, fontSize: 15, style: 'tableHeader', width: 'auto', bold: true },
								],
							},
						],
						[{
							stack: [
								[ 'CLIENTE:', venda.cliente?.razaoSocialOuNome ],
								[ 'CPF/CNPJ:', venda.cliente?.cnpjOuCpf ],
								[ 'ENDEREÇO:', `${endereco?.rua} Nº ${endereco?.numero}` ],
								[ 'COMPLEMENTO:', endereco?.complemento ],
								[ 'BAIRRO:', endereco?.bairro ],
								[ 'CIDADE:',  `${endereco?.cidade} - ${endereco?.uf}`],
								[ 'CEP:',  `${endereco?.cep}`],
								[ 'TELEFONE:', venda.cliente?.telefones.length === 0 ? 'Não consta' : venda.cliente?.telefones ],
								[ 'VENDEDOR:', venda.vendedor && venda.vendedor.length > 0 ? `${venda.vendedor[0].nome}` : 'Sem vendedores'],
								[ 'DATA DA VENDA:',  venda.dataHora ? dateTimeToPtBrFormat(venda.dataHora) : ''],
								[ 'NOTA FISCAL:',  venda.notas[0] ? `${venda.notas[0].nnf} / ${venda.notas[0].serie}` : 'Não possui NF'],
								[ dataHoraDaNota.toString() != '' ?  'DATA DE FATURAMENTO:' : '', dateTimeToPtBrFormat(dataHoraDaNota.toString())],
							].map(([ campo, valor ]) => ({
								columns: [
									{ text: campo, bold: true, width: 'auto', margin: [0, 0, 5, 0] },
									{ text: valor },
								],
								listType: 'none',
							})),
						}],
					],
				},
				margin: [0, 0, 0, 10],
			},
			{ image: textToBase64Barcode(venda.identificador || ''), alignment: 'center' },
			buildTabelaItensDaVenda(produtosFiltrados || [],
				[
					{ text: 'skuPai', bold: true, fontSize: 7 },
					{ text: 'sku', bold: true, fontSize: 7 },
					{ text: 'nome', bold: true, fontSize: 7 },
					{ text: 'quantidade', bold: true, fontSize: 7 },
					{ text: 'valor', bold: true, fontSize: 7 },
				],
			),
			buildTabelaQuantidadeTotal(produtosFiltrados || []),
			buildLinhasDeVolume(venda.volumes),
			{
				qr: qrData,
				alignment: 'center',
				fit: 150,
				margin: [0, 50, 0, 0],
			},
		],
	};
	return new Promise(resolve => {
		pdfMake.createPdf(docDefinition).getBase64((result) => resolve(result))
	})
}

function buildTabelaItensDaVenda(itensDaVenda: ItemDaVendaComReferencias[], colunas: ContentText[]): Content {
	return {
		table: {
			widths: [70, 70, '*', 40, 75],
			headerRows: 1,
			body: buildLinhasItensDaVenda(itensDaVenda, colunas),
		},
	}
}

function buildTabelaQuantidadeTotal(itensDaVenda: ItemDaVendaComReferencias[]): Content {
	let quantidadeTotal = 0;
	let valorTotal = 0;

	itensDaVenda.forEach((item) => {
		if (item.motivoCancelamento !== null) return
		quantidadeTotal += item.quantidade;
		valorTotal += obterTotalLiquidoDoItem(item, item.quantidade);
	})

	const valorTotalFormatado = formatarMoeda(valorTotal);

	return {
		table: {
			widths: ['*'],
			body: [
				[
					{
						columns: [
							{ text: 'TOTAL: ', fontSize: 12, width: '*', bold: true, alignment: 'center' },
							{ text: `${quantidadeTotal}`, fontSize: 11, bold: true, width: 40, alignment: 'center', margin: [0, 0, 20, 0] },
							{ text: `R$ ${valorTotalFormatado}`, fontSize: 11, bold: true, width: 75, alignment: 'center' },
						],
					},
				],
			],
		},
	}
}

function buildLinhasItensDaVenda(itensDaVenda: ItemDaVendaComReferencias[], colunas: ContentText[],
): any[] {
	const body: ContentText[][] = []
	body.push([
		{ text: 'Referencia', bold: true, fontSize: 10, alignment: 'center' },
		{ text: 'Codigo', bold: true, fontSize: 10, alignment: 'center' },
		{ text: 'Produto', bold: true, fontSize: 10, alignment: 'center' },
		{ text: 'Qtde', bold: true, fontSize: 10, alignment: 'center' },
		{ text: 'Valor', bold: true, fontSize: 10, alignment: 'center' },
	])

	const itensDaVendaAgrupados: any = []
	itensDaVenda.forEach((item) => {

		const indice = itensDaVendaAgrupados.findIndex(
			({ nome }) => nome === item.produto.nome,
		)
		indice === -1
			? itensDaVendaAgrupados.push({
				nome: item.produto.nomeCompleto || displayNomeCompletoDoProduto(item.produto),
				quantidade: item.quantidade,
				sku: item.produto.sku,
				skuPai: item.produto.tipo === 'Variante' ? `${item.produto.skuPai}` : 'Sem Variante',
				valor: 'R$' + formatarMoeda(obterTotalLiquidoDoItem(item, item.quantidade)),
				isCancelado: item.motivoCancelamento !== null,
			})
			: itensDaVendaAgrupados.splice(indice, 1, {
				...itensDaVendaAgrupados[indice],
				quantidade: item.quantidade + itensDaVendaAgrupados[indice].quantidade,
			})
	})

	itensDaVendaAgrupados.forEach(itemDaVenda => {
		const dados: ContentText[] = []
		colunas.forEach(coluna => {
			let conteudoDaLinha = itemDaVenda[coluna.text as string]
			if (coluna.text === 'sku') conteudoDaLinha = itemDaVenda.sku
			if (coluna.text === 'nome') conteudoDaLinha = itemDaVenda.nome
			if (coluna.text === 'skuPai') conteudoDaLinha = itemDaVenda.skuPai
			dados.push({
				text: conteudoDaLinha, bold: false, fontSize: 10, alignment: 'center', decoration: itemDaVenda.isCancelado ? 'lineThrough' : undefined,
			})
		})
		body.push(dados)
	})
	return body
}

export async function gerarComprovanteDeDemonstracao80mm(qrData: string, produtosComReferencia?: ItemDaVendaComReferencias[], vendaImpressao?: Venda): Promise<void> {
	const response: any = await request.post(`/vendas/gerar-comprovante-de-demonstracao-80mm`,
		{
			qrData: qrData,
			venda: vendaImpressao,
			produtosComReferencia: produtosComReferencia,

		},
		{
			headers: {
				'Content-Type': 'application/json',
			},
			responseType: 'arraybuffer',
		},
	).catch(async error => {
		if (error?.response?.data) {
			let dataJson
			try {
				dataJson = JSON.parse(new TextDecoder("utf-8").decode(error.response.data))
				// eslint-disable-next-line no-empty
			} catch { }
			if (dataJson) {
				throw new Error(dataJson)
			}
			throw error
		}
	})

	const url = window.URL.createObjectURL(new Blob([response]))
	Promise.race([
		new Promise<void>((resolve, reject) => {
			print({
				documentTitle: `ROMANEIO.pdf`,
				printable: url,
				onError: error => reject(error),
				onLoadingEnd: () => resolve(),
			})
		}),
		new Promise((resolve, reject) =>
			setTimeout(
				() => reject('Erro ao gerar impressão, verifique as configurações'),
				15000,
			),
		),
	])
}

export async function imprimirRomaneio(venda: Venda, qrData: string, produtosComReferencia?: ItemDaVendaComReferencias[]): Promise<void> {
	const valeTrocaBase64: string = await gerarRomaneio(venda, qrData, produtosComReferencia)
	return new Promise((resolve, reject) => {
		print({
			printable: valeTrocaBase64,
			type: 'pdf',
			base64: true,
			onError: reject,
			onLoadingEnd: resolve,
		})
	})
}

function buildLinhasDeVolume(volumes: string[]): any {
	const listaVolumeRetorno = listaVolumeSplit(volumes).map(element => {
		return element.map((volume) => {
			return { image: textToBase64Barcode(volume) }
		})
	})
	return listaVolumeRetorno


}

function listaVolumeSplit(volumes: string[]): string[][] {
	const lista: string[][] = []
	let listaAtual: string[] = []

	volumes.forEach(volume => {
		if (listaAtual.length < 5)
			listaAtual.push(volume)
		else {
			lista.push(listaAtual)
			listaAtual = []
			listaAtual.push(volume)
		}
	})
	lista.push(listaAtual)
	return lista
}
