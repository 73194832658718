












































































import Confirmacao from '@/components/ui/Confirmacao.vue'
import type { Venda } from '@/models'
import { UsuarioBase, VendaComReferencias } from '@/models'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { EditarUmaVendaUseCase, VendedorEdit, VendedorItemEdit } from '@/usecases/venda/EditarUmaVendaUseCase'
import { Vue, Component, Prop, Ref} from 'vue-property-decorator'
import DialogoDeInformarVendedorDaVenda from './DialogoDeInformarVendedorDaVenda.vue'
import SeletorDeUsuario from '@/components/usuario/SeletorDeUsuario.vue'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
@Component({
	components: {	
		DialogoDeInformarVendedorDaVenda,
		Confirmacao,
		SeletorDeUsuario,
	},
})
export default class DialogoDeInformarVendedorNaVenda extends Vue {
	@Prop({type: Object}) venda!: Venda
	@Ref() confirmacaoDeEdicaoDeVendedor!: Confirmacao
	mostra: boolean | null = false
	salvando: boolean | null = false
	vendedor: UsuarioBase[] | null = null
	vendedorPrincipal: UsuarioBase | null = null
	vendaEmEdicao: VendaComReferencias | null = null

	editarVendaUseCase = new EditarUmaVendaUseCase()

	mostrar(id: string | undefined, vendaComReferencias: VendaComReferencias | null) {
		if (vendaComReferencias) {
			this.vendaEmEdicao = vendaComReferencias
		}
		if (UserLoginStore.permiteRegraDeNegocio('pode-alterar-vendedor')) {
			this.mostra = true
			this.vendedorPrincipal = this.venda.vendedor && this.venda.vendedor.length > 0 ? this.venda.vendedor[0] : null
		} else {
			this.mostra = false
		}
	}

	abrirConfirmacao(vendedor: UsuarioBase){
		if(!vendedor) return
		this.confirmacaoDeEdicaoDeVendedor.mostrar()
	}

	async confirmarEdicao() {
		if (!this.vendaEmEdicao) return

		try {
			this.salvando = true
			this.venda.vendedor = this.vendedorPrincipal ? [this.vendedorPrincipal] : null

			const edicaoDeVendedor = {
				id: this.vendaEmEdicao.id,
				vendedores: this.vendedorPrincipal ? [this.vendedorPrincipal] : null,
				itens: this.vendaEmEdicao.itens.map(item => {
					if (this.vendedorPrincipal && !item.vendedor) {
						return {
							id: item.id,
							vendedor: this.vendedorPrincipal,
						} as VendedorItemEdit
					} else {
						return {
							id: item.id,
							vendedor: item.vendedor,
						} as VendedorItemEdit
					}
				}),
			} as VendedorEdit

			await this.editarVendaUseCase.atualizarVendedores(edicaoDeVendedor)

			this.$emit('atualizarVendedores', edicaoDeVendedor)
		} catch(error) {
			AlertModule.setError(error)
		} finally {
			this.salvando = false
			this.mostra = false
		}
	}

	fechar() {
		this.mostra = false
		this.vendedorPrincipal = null
	}
	
}
